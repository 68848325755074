<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">
        Shared History
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Plans</a></li>
          <li class="breadcrumb-item active" aria-current="page">Shared History</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Filter</h4>
            <ValidationObserver v-slot="{}" ref="form">
              <form @change="filterShared" class="forms-sample">
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider name="date_range" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="date_range" horizontal label="Date Range">
                        <date-picker v-model="date_range" style="width:100%;" @change="filterShared" type="date" value-type="format" format="YYYY-MM-DD" range></date-picker>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="city_id" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="city_id" horizontal label="City">
                        <b-form-select id="city_id" v-model="city_id" :options="cities"/>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="plan_no">
                      <b-form-group slot-scope="{ errors }" label-for="plan_no" horizontal label="Plan No">
                        <b-form-input type="text" id="plan_no" v-model="plan_no" placeholder="Plan No"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider action="#">
                      <b-form-group class="form-group d-lg-flex" label="Per Page">
                        <b-form-input type="number" class="form-control" placeholder="perPage" v-model="paginate"></b-form-input>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                </div>
              </form>
            </ValidationObserver>

          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
<!--            <h4 class="card-title">{{ list_title }}</h4>-->
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <h4 class="card-title">{{ list_title }}</h4>
              <download-excel
                class="btn btn-success"
                :data="shared"
                :fields="json_fields"
                worksheet="My Worksheet"
                name="shared_plan.xlsx">
                Download Plans
              </download-excel>
            </div>
            <b-table :items="shared" id="table-list" responsive :busy="isBusy" :per-page="perPage" :current-page="currentPage" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template v-slot:cell(created_at)="data">
                <span>{{ formatTime(data.item.created_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
              <template v-slot:cell(updated_at)="data">
                <span>{{ formatTime(data.item.updated_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
              <template v-slot:cell(name)="data">
                <span v-if="data.item.customer != null">
                  <router-link :to="{name: 'customer-view', params: {id: data.item.customer.id}}" target="_blank">{{`${data.item.customer.first_name} ${data.item.customer.last_name}`}}</router-link>
                </span>
                <span v-if="data.item.customer == null">
                  N/A
                </span>
              </template>
              <template v-slot:cell(phone)="data">
                <span>
                  {{ data.item.customer ? data.item.customer.phone : '' }}
                </span>
              </template>
              <template v-slot:cell(planNo)="data">
                <span>
                  {{ data.item.plan.planNo }}
                </span>
              </template>
              <template v-slot:cell(district)="data">
                <span>
                  {{ data.item.plan.district }}
                </span>
              </template>
              <template v-slot:cell(viewed)="data">
                <span>
                  {{ data.item.is_viewed }}
                </span>
              </template>
              <template v-slot:cell(shared_to)="data">
                <span v-if="data.item.shared_to != null">
                  <router-link :to="{name: 'customer-view', params: {id: data.item.shared_to.id}}" target="_blank">{{`${data.item.shared_to.first_name} ${data.item.shared_to.last_name}`}}</router-link>
                </span>
                <span v-if="data.item.shared_to == null">
                  N/A
                </span>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right">
            </b-pagination>
          </div>
        </div>
      </div>
      </div>
    <div>
    </div>
  </section>
</template>
<script>
/* eslint-disable no-debugger, no-console */

import Vue from "vue"
import { ValidationObserver } from 'vee-validate'
import SortedTablePlugin from "vue-sorted-table"
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import _ from 'lodash'

import helper from '../../util/helper.js'

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  data: function() {
    return {
      searchInput: "",
      sortBy: 'name',
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      date_range: "",
      start_date: "",
      end_date: "",
      plan_no: "",
      city_id: "",
      list_title: "Shared History",
      currentPage: 1,
      perPage: 0,
      paginate: 10,
      isBusy: true,
      fields: [
        { key: 'id', sortable: true },
        { key: 'planNo', sortable: true },
        { key: 'name', sortable: true },
        { key: 'phone', sortable: true },
        { key: 'district', sortable: true},
        { key: 'viewed', sortable: true },
        { key: 'shared_to', sortable: true },
        { key: 'created_at', sortable: true },
      ],
      json_fields: {
        'ID': 'id',
        "Plan Number": "plan.planNo",
        'District': 'plan.district',
        'Plan ID': 'plan.id',
        'Sender Name': 'customer.first_name',
        'Sender ID': 'customer.id',
        'Viewer Name': 'shared_to.first_name',
        'Viewer Id': 'shared_to.id',
        'Is Viewed': 'is_viewed',
      }
    };
  },
  computed: {
    rows() {
      return this.$store.state.plan.shared_meta.total
    },
    paginationPageSize() {
      return this.$store.state.plan.shared_meta.paginate
    },
    shared() {
      return this.$store.state.plan.shared
    },
    cities() {
      let cities = this.$store.getters['city/listCities']
      cities.unshift({value: "", text: "Select City"})
      return cities
    }
  },
  watch: {
    currentPage() {
      if(this.currentPage != this.$store.state.plan.favourite_plot_meta.current_page)
        this.fetchShared(this.currentPage)
    },
    date_range(newVal) {
      if(newVal[0] != null && newVal[1] != null) {
        this.start_date = newVal[0]
        this.end_date = newVal[1]
      }
      else {
        this.start_date = ""
        this.end_date = ""
      }
    }
  },
  methods: {
    formatTime(time, format) {
      return helper.formatTime(time, format)
    },
    filterShared: _.debounce(function() {
      this.fetchShared(this.currentPage)
    }, 2000),
    fetchCities() {
      this.isBusy = true

      const payload = {
        meta: {
          page: 1,
          paginate: 200
        },
      }
      this.$store.dispatch("city/fetchCities", payload)
    },
    fetchShared(current_page = 1) {
      this.isBusy = true
      let payload = {
        meta: {
          page: current_page,
          paginate: this.paginate,
          start_date: this.start_date,
          end_date: this.end_date,
          plan_no: this.plan_no,
          plot_no: this.plot_no,
          city_id: this.city_id
        },
      }
      
      for (const [key, value] of Object.entries(payload.meta)) {
        if(value == "")
          delete payload.meta[key]
      }

      this.$store.dispatch("plan/sharedHistory", payload)
      .then(() => {
        this.isBusy = false
      })
      .catch(() => {
        this.isBusy = false
      })
    },
  },
  created() {
    this.fetchCities()
    this.fetchShared()
 },
 components: {
   ValidationObserver,
   DatePicker
 }
}
</script>
